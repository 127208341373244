@tailwind base;
@tailwind components;
@tailwind utilities;

/*Original begine here*/
@layer base {
  .bodyP {
    @apply flex items-center justify-center w-screen h-screen bg-white;
    height: 20vh;
  }
}

@layer components {
  .brands-wrapper {
    @apply flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-4;
  }
}

.brands-wrapper li {
  padding-left: 60px;
}
/*original ends here*/
